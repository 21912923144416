<template>
    <div v-if="Object.keys(labels).length" class="space-authors client-content-padding">
        <div class="row">
            <div v-for="(author, index) in data.authors" :key="`space-author-${index}`" class="col-12 col-md-6">
                <div class="space-author">
                    <img :src="author.photo" />
                    <div class="description">
                        <h6>
                            {{ author.name }}
                        </h6>
                        <span v-html="author.description"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SpaceAuthors',
    props: {
        labels: {
            type: Object,
            default: () => {
                return {}
            }
        },
        data: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
    @import "@/styles/common";

    .space-authors {
        margin-top: -30px;
        
        .space-author {
            width: 100%;
            max-height: 15rem;
            display: flex;
            margin-top: 30px;

            img {
                width: 200px;
                object-fit: contain;
                object-position: center top;
                margin-right: 15px;

                @media (min-width: 1700px) {
                    width: 300px;
                    object-position: center top;
                }
            }

            .description {
                max-height: 15rem;
                overflow: hidden;
            }
            span {
                display: block;
            }
        }
    }
</style>